import React, { useState } from 'react';
import {Button, message, Spin, Tooltip, Typography} from "antd";
import {CopyToClipboard} from "react-copy-to-clipboard";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {dateOptions} from "../../../helpers";
import parser from 'cron-parser';

import { getOutputImages, getOutputDetails, stopProject, getOutputLogs } from '../ProjectsApi/projectsApi';
import { ReactComponent as CopyProjectIcon } from 'svg/icon/copy-project.svg';
import { ReactComponent as DownIcon } from 'svg/icon/down-arrow.svg'
import { ReactComponent as PlayIcon} from 'svg/icon/start-project.svg';
import { ReactComponent as StopProjectIcon } from 'svg/icon/stop-project.svg'

import { ProjectsReport } from '../ProjectsReports/ProjectsReport';
import { ValidationLogs } from '../ProjectsReports/ValidationLogs';
import { runProject } from '../ProjectsApi/projectsApi'
import { isProjectRunning, StatusBlock } from '../StatusBlock/StatusBlock';
import MoreDetailedCard from '../MoreDetailedCard/MoreDetailedCard';
import { GenerationStatus } from '../../../constants';

import styles from './ProjectOutputCard.module.css';

const {Text} = Typography;
const {STOPPING, IN_PROGRESS} = GenerationStatus;

const ProjectOutputCard = ({projectId, disabled, output, outputStatus, projectStatus, feed, outputsAmount}) => {
    const [isMoreDetailedOpen, setIsMoreDetailedOpen] = useState(false);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [isPreviewLoading, setIsPreviewLoading] = useState(false);
    const [previewPage, setPreviewPage] = useState(0);
    const [previewLoaded, setPreviewLoaded] = useState(false);
    const [modalOpen, setModalOpen] = useState(false)
    const [viewReport, setViewReport] = useState(null);
    const [viewReportLoading, setViewReportLoading] = useState(false);
		const [outputLoading, setOutputLoading] = useState(false);
    const [outputDetails, setOutputDetails] = useState(null);
    const [reportTitle, setReportTitle] = useState('');
    const lastValidation = outputDetails?.generation_logs[0] || null;
    const validationLogs = outputDetails?.generation_logs.slice(1, 100) || [];

    const getPreviews = async (id, append = false, page = previewPage) => {
        try {
            setIsPreviewLoading(true);
            const data = await getOutputImages(id, page);
            if (!data) return;
            setPreviewUrls(append ? previewUrls.concat(data.previews) : data.previews);
            if (previewPage < data.max_pages) {
                setPreviewPage(previewPage + 1);
            } else {
                setPreviewLoaded(true);
            }
        } finally {
            setIsPreviewLoading(false);
        }
    };

  const startOutputGeneration = async () => {
    try {
      setOutputLoading(true);
      const result = outputsAmount === 1
        ? await runProject(projectId)
        : await runProject(projectId, [output.id]);
      if (result) {
        message.success('Output generation started!');
      }
    } finally {
      setOutputLoading(false);
    }
  };

  const toggleMoreDetailedInfo = async () => {
    if (isMoreDetailedOpen) {
      setIsMoreDetailedOpen(false);
      return;
    }

    setIsMoreDetailedOpen(true);
    setPreviewPage(0);
    setPreviewLoaded(false);

    try {
      const outputLogs = await getOutputDetails(output.id, setIsPreviewLoading);
      setOutputDetails(outputLogs);
      await getPreviews(output.id);
    } catch (error) {
      console.error('Failed to get previews:', error);
    }
  };

    const scrolled = async (event) => {
        if (((event.target.offsetWidth + event.target.scrollLeft) >= event.target.scrollWidth) && !previewLoaded) {
           await getPreviews(output.id, true)
        }
    };

    const renderOutputSlides = () => {
        if (isPreviewLoading && previewPage === 0) {
            return (
                <div className={styles.slidesLoading}>
                    <Spin/>
                </div>
            );
        }
        if (!!previewUrls && previewUrls.length > 0) {
            return (
                <div className={styles.sliderBlock}>
                    <div className={styles.scrollBlock} onScroll={scrolled}>
                        {previewUrls.map((url, index) => (
                            <LazyLoadImage
                                key={`img_${url}`}
                                src={url}
                                alt={`output ${output.id} image ${index}`}
                                height={136}
                            />
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    };

    const viewReportClick = async (validationLogId) => {
      await setModalOpen(true);
      const outputLogs = await getOutputLogs(validationLogId, setViewReportLoading);
      setViewReport(outputLogs);
      setReportTitle('View Report');
    };

    const viewOutputValidationLogs = () => {
      const { generation_logs, ...outputValidationLogs } = outputDetails;
      setModalOpen(true);
      setViewReport(outputValidationLogs);
      setReportTitle('Output validation logs');
    };

    const closeViewReport = () => {
      setModalOpen(false);
      setViewReport(null);
    };

		const isProjectStopping = projectStatus === STOPPING || outputStatus?.status === STOPPING;
		const isOutputRunning = isProjectRunning(outputStatus?.status);
    return (
        <div className={styles.projectCard}>
            <div className={`${styles.topBlock} ${styles.borderBottom}`}>
                <div className={styles.projectInfo}>
                    <Text className={styles.projectName}>{output.name}</Text>
                    <Text className={styles.projectItems}>{output.row_count || 0} Items</Text>
                </div>
                <div className={styles.statusBlock}>
                    <StatusBlock type='Output' status={outputStatus?.status} progress={outputStatus?.progress} />
                </div>
                <div className={styles.actionButtonsBlock}>
	                {outputStatus?.status === IN_PROGRESS || outputStatus?.status === STOPPING ?
		                <Tooltip title="Stop feed" placement="bottom">
			                <Button
				                icon={<StopProjectIcon/>}
				                onClick={() => stopProject(projectId, {outputs: [output.id]})}
				                shape="circle"
				                className={`${styles.actionButton} ${isProjectStopping ? styles.disabledActionButton : ''}`}
				                disabled={isProjectStopping}
		                  />
		                </Tooltip>:
		                <Tooltip title="Run feed" placement="bottom">
		                  <Button
				                icon={<PlayIcon/>}
				                shape="circle"
				                disabled={disabled || isOutputRunning}
				                className={`${styles.actionButton} ${disabled || isOutputRunning ? styles.disabledActionButton : ''}`}
				                onClick={startOutputGeneration}
				                loading={outputLoading}
			                />
	                </Tooltip>}
                    <CopyToClipboard
                        text={output.output_file}
                        onCopy={() =>
                            message.success({
                                content: `Output URL copied!\n${output.output_file}`,
                                duration: 3
                            })
                        }
                    >
                        <Tooltip title='Copy feed link' placement='bottom'>
                            <Button
                                disabled={disabled}
                                icon={<CopyProjectIcon/>}
                                shape="circle"
                                className={`${styles.actionButton} ${disabled ? styles.disabledActionButton : ''}`}
                            />
                        </Tooltip>
                    </CopyToClipboard>

                </div>
            </div>
            <div className={`${styles.bottomBlock} ${disabled ? styles.disabledBlock : ''}`}>
                <button
                    className={`${styles.moreDetailedButton} ${isMoreDetailedOpen ? styles.moreDetailedButtonOpen : ''}`}
                    onClick={toggleMoreDetailedInfo}
                >
                    More detailed
                    <DownIcon/>
                </button>
                {isMoreDetailedOpen && (
                    <div className={styles.previewBlock}>
                        {renderOutputSlides()}
                        {outputDetails &&
                          <div className={styles.previewInnerBlock}>
                            <div>
                              <h3 className={styles.previewInnerTitle}>
                                Created: {new Date(outputDetails?.created).toLocaleString('en-US', dateOptions)}
                              </h3>
                            </div>
                            <Button
                              className={styles.outputLogsButton}
                              onClick={viewOutputValidationLogs}
                            >
                              Output validation logs
                            </Button>
                          </div>
                        }
                      <div className={styles.previewInnerBlock}>
                        <h3 className={styles.previewInnerTitle}>
                          Next upload
                        </h3>
                      </div>
                      {feed.schedule && (
                        <div className={`${styles.scheduleBlock} ${styles.borderBottom}`}>
                                <Text className={styles.scheduleTitle}>Scheduled</Text>
                                <Text className={styles.scheduleDate}>
                                    {parser.parseExpression(
                                        feed.schedule.value,
                                        {
                                            tz: 'Europe/Moscow'
                                        })
                                        .next()
                                        .toDate()
                                        .toLocaleString('en-US', dateOptions)
                                    }
                                </Text>
                            </div>
                        )}
                      {lastValidation && (
                          <MoreDetailedCard
                            log={lastValidation}
                            viewReportClick={viewReportClick}
                            lastUpload
                          />
                        )}
                      <div className={styles.sessionBlock}>
                        <h3 className={styles.previousUpload}>Uploaded sessions</h3>
                        <div className={styles.validationLogsWrapper}>
                        {!!validationLogs.length &&
                          validationLogs.map((log) =>  (
                              <MoreDetailedCard
                                key={log.id}
                                log={log}
                                viewReportClick={viewReportClick}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                )}
            </div>
            <ProjectsReport
                open={modalOpen}
                handleClose={closeViewReport}
            >
                <ValidationLogs
                  title={reportTitle}
                  logs={viewReport}
                  isLoading={viewReportLoading}
                />
            </ProjectsReport>
        </div>
    );
};

export default ProjectOutputCard;
