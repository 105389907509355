const CORS_PROXY = 'https://summer-credit-ca8e.egomezs210686.workers.dev/?url=';

export const cropImage = (dataUrl, useFirstPixel = false) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      const bgColor = useFirstPixel
        ? [
            (data[0] +
              data[4] +
              data[canvas.width * 4] +
              data[canvas.width * 4 + 4]) /
              4, // red
            (data[1] +
              data[5] +
              data[canvas.width * 4 + 1] +
              data[canvas.width * 4 + 5]) /
              4, // green
            (data[2] +
              data[6] +
              data[canvas.width * 4 + 2] +
              data[canvas.width * 4 + 6]) /
              4, // blue
            (data[3] +
              data[7] +
              data[canvas.width * 4 + 3] +
              data[canvas.width * 4 + 7]) /
              4, // alpha
          ]
        : [255, 255, 255, 255];

      let minX = canvas.width;
      let minY = canvas.height;
      let maxX = 0;
      let maxY = 0;

      // Left to right scan
      for (let x = 0; x < canvas.width && minX === canvas.width; x++) {
        for (let y = 0; y < canvas.height; y++) {
          const idx = (canvas.width * y + x) * 4;
          const rgba = [data[idx], data[idx + 1], data[idx + 2], data[idx + 3]];
          if (!isBackground(rgba, bgColor, useFirstPixel)) {
            minX = x;
            break;
          }
        }
      }

      // Right to left scan
      for (let x = canvas.width - 1; x >= 0 && maxX === 0; x--) {
        for (let y = 0; y < canvas.height; y++) {
          const idx = (canvas.width * y + x) * 4;
          const rgba = [data[idx], data[idx + 1], data[idx + 2], data[idx + 3]];
          if (!isBackground(rgba, bgColor, useFirstPixel)) {
            maxX = x;
            break;
          }
        }
      }

      // Top to bottom scan
      for (let y = 0; y < canvas.height && minY === canvas.height; y++) {
        for (let x = minX; x <= maxX; x++) {
          const idx = (canvas.width * y + x) * 4;
          const rgba = [data[idx], data[idx + 1], data[idx + 2], data[idx + 3]];
          if (!isBackground(rgba, bgColor, useFirstPixel)) {
            minY = y;
            break;
          }
        }
      }

      // Bottom to top scan
      for (let y = canvas.height - 1; y >= 0 && maxY === 0; y--) {
        for (let x = minX; x <= maxX; x++) {
          const idx = (canvas.width * y + x) * 4;
          const rgba = [data[idx], data[idx + 1], data[idx + 2], data[idx + 3]];
          if (!isBackground(rgba, bgColor, useFirstPixel)) {
            maxY = y;
            break;
          }
        }
      }

      const cropWidth = maxX - minX + 1;
      const cropHeight = maxY - minY + 1;

      const croppedCanvas = document.createElement('canvas');
      croppedCanvas.width = cropWidth;
      croppedCanvas.height = cropHeight;

      const croppedCtx = croppedCanvas.getContext('2d');
      croppedCtx.drawImage(
        canvas,
        minX,
        minY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight,
      );

      resolve(croppedCanvas.toDataURL());
    };
    img.src = `${CORS_PROXY}${encodeURIComponent(dataUrl)}`;
  });
};

function isBackground(rgba, bgColor, useFirstPixel) {
  return useFirstPixel
    ? rgba.slice(0, 3).every((value, i) => Math.abs(value - bgColor[i]) <= 15)
    : rgba.slice(0, 3).every((value) => value >= 240);
}
