import React, { useState, useEffect } from 'react';
import { Button, Typography } from 'antd';
import SelectControl from '../../controls/SelectControl/SelectControl';
import SelectFieldControl from '../../controls/SelectFieldControl/SelectFieldControl';
import InputControl from '../../controls/InputControl/InputControl';
import ConditionGroup from './ConditionGroup';

import { ReactComponent as CloseIcon } from 'svg/icon/close-black.svg';

import styles from './ConditionGroup.module.css';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default (props) => {
  const [condition, setCondition] = useState(props.condition);

  useEffect(() => {
    props.onUpdate();
  }, [condition]);

  const onUpdate = () => {
    props.onUpdate();
  };

  useEffect(() => {
    setCondition(props.condition);
  }, [props.condition]);

  const createFieldCondition = () => {
    return {
      fieldId: props.fields[0].id,
      conditionType: props.conditionTypeDataSource[0].id,
      value: '',
    };
  };
  const addCondition = () => {
    props.condition.conditions.push(createFieldCondition());
    setCondition({ ...props.condition });
  };

  const addGroup = () => {
    const group = {
      group: true,
      type: props.typeDataSource[0].id,
      conditions: [],
    };
    props.condition.conditions.push(group);
    setCondition({ ...props.condition });
  };

  const onFieldChanged = (condition, value) => {
    const data = props.condition.conditions.find((t) => t === condition);
    data.fieldId = value;
    if (
      (value.startsWith('picture') || value.startsWith('image')) &&
      data.conditionType === 'EXISTS'
    ) {
      data.conditionType = '$eq';
      data.value = '';
    }
    setCondition({ ...props.condition });
  };

  const onConditionTypeChanged = (condition, value) => {
    const data = props.condition.conditions.find((t) => t === condition);
    data.conditionType = value;
    if (value === 'EXISTS') {
      data.value = 1;
    }
    setCondition({ ...props.condition });
  };

  const onValueChanged = (condition, value) => {
    const data = props.condition.conditions.find((t) => t === condition);
    data.value = value;
    setCondition({ ...props.condition });
  };

  const changeType = (value) => {
    props.condition.type = value;
    setCondition({ ...props.condition });
  };

  const deleteItem = (item) => {
    props.condition.conditions = props.condition.conditions.filter(
      (t) => t !== item,
    );
    setCondition({ ...props.condition });
  };

  const isCorrectField = (condition) => {
    if (condition) {
      const type = props.conditionTypeDataSource.find(
        (t) => t.id === condition.conditionType,
      );
      const res =
        !type ||
        !type.number ||
        Number.parseFloat(condition.value) ||
        condition.value === '0';
      return res;
    }

    return true;
  };

  const isFieldMissed = (condition) => {
    if (
      !condition ||
      !condition.fieldId ||
      condition.conditionType === 'EXISTS'
    ) {
      return false;
    }
    return !props.fields.find((t) => t.id === condition.fieldId);
  };

  const isOperandMissed = (condition) => {
    if (!condition || !condition.conditionType) {
      return false;
    }
    return !props.conditionTypeDataSource.find(
      (t) => t.id === condition.conditionType,
    );
  };

  const getTypeName = (id) => {
    return props.typeDataSource.find((t) => t.id === id)?.name;
  };

  const numberValues = ['number', 'integer', 'float', 'double'];

  const getFilterConditionTypes = (fieldId) => {
    const field = props?.productFields[fieldId];
    if (field === 'bool') {
      const filtered = props.conditionTypeDataSource.filter((t) => t.bool);
      return [...filtered];
    } else if (!field || !numberValues.find((t) => t === field)) {
      let filtered = props.conditionTypeDataSource.filter(
        (t) => t.string === undefined || t.string === true,
      );
      if (fieldId.startsWith('picture') || fieldId.startsWith('image')) {
        filtered = filtered.filter((t) => t.id !== 'EXISTS');
      }
      return [...filtered];
    } else {
      const filtered = props.conditionTypeDataSource.filter(
        (t) => t.number === undefined || t.number === true,
      );
      return [...filtered];
    }
  };

  return (
    <div className={!props.isChild ? styles.wrapper : ''}>
      <div
        className={`${globalStyles.selectBlock} ${globalStyles.bigGapBlock}`}
      >
        {condition.conditions.length > 1 && (
          <div
            className={`${styles.switchButtonBlock} ${styles.switchButtonBlock}`}
          >
            <button
              className={`${styles.switchButton} ${condition.type === '$and' ? styles.activeSwitchButton : ''}`}
              onClick={() => changeType('$and')}
            >
              AND
            </button>
            <button
              className={`${styles.switchButton} ${condition.type === '$or' ? styles.activeSwitchButton : ''}`}
              onClick={() => changeType('$or')}
            >
              OR
            </button>
          </div>
        )}
      </div>
      <div
        className={
          !props.isChild
            ? `${styles.conditionsWrapper} ${globalStyles.bottomBorderDivider}`
            : styles.conditionChildrenWrapper
        }
      >
        {condition.conditions.map((t, i) => {
          return (
            <div key={`conditionRoot_${i}`}>
              <div>
                {i > 0 && (
                  <div className={styles.conditionType}>
                    {getTypeName(condition.type)}
                  </div>
                )}
                <div
                  className={`${globalStyles.spaceBetweenBlock} ${styles.conditionTitle}`}
                >
                  <Text>{t.group ? 'Condition group' : 'Field condition'}</Text>
                  <Button
                    shape='circle'
                    type='text'
                    size='small'
                    icon={<CloseIcon />}
                    onClick={() => deleteItem(t)}
                    className={styles.closeButton}
                  />
                </div>
              </div>

              {t.group && (
                <ConditionGroup
                  level={props.level + 1}
                  onUpdate={onUpdate}
                  isChild={true}
                  condition={t}
                  product={props.product}
                  conditionTypeDataSource={props.conditionTypeDataSource}
                  fields={props.fields}
                  typeDataSource={props.typeDataSource}
                  productFields={props.productFields}
                />
              )}

              {!t.group && (
                <div
                  className={`${globalStyles.selectBlock} ${globalStyles.gapBlock} ${styles.groupBlock}`}
                >
                  <div>
                    <SelectFieldControl
                      value={t.fieldId}
                      onChange={(value) => onFieldChanged(t, value)}
                      product={props.product}
                      fields={props.productFields}
                      style={styles.inputRound}
                      isRoundSelect
                    />
                    {isFieldMissed(t) && (
                      <div className={styles.errorMessage}>Field is missed</div>
                    )}
                  </div>
                  <div>
                    <SelectControl
                      placeholder='Select condition variant'
                      value={t.conditionType}
                      onChange={(value) => onConditionTypeChanged(t, value)}
                      dataSource={[...getFilterConditionTypes(t.fieldId)]}
                      style={styles.inputRound}
                      isRoundSelect
                    />
                    {isOperandMissed(t) && (
                      <div className={styles.errorMessage}>
                        Operand is missed
                      </div>
                    )}
                  </div>

                  {t.conditionType === 'EXISTS' ? (
                    <SelectControl
                      style={styles.inputRound}
                      isRoundSelect
                      className={`${styles.select}`}
                      value={t.value ?? 0}
                      onChange={(value) => {
                        onValueChanged(t, value);
                      }}
                      defaultValue={1}
                      dataSource={[
                        { id: 1, name: 'true' },
                        { id: 0, name: 'false' },
                      ]}
                    />
                  ) : (
                    <div>
                      <InputControl
                        value={t.value}
                        onChange={(e) => onValueChanged(t, e.target.value)}
                        hasErrors={!isCorrectField(t)}
                        customStyles={`${styles.inputRound} ${styles.inputPadding}`}
                        placeholder='Add condition value'
                      />

                      {!isCorrectField(t) && (
                        <div className={styles.errorMessage}>
                          Incorrect number value
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div
        className={`${props.isChild ? styles.buttonsForGroupCondition : styles.addConditionButtonBlock} ${globalStyles.selectBlock}`}
      >
        <Button
          type='text'
          className={`${styles.addConditionButton} ${props.isChild ? styles.groupConditionButton : ''}`}
          onClick={addCondition}
          block={props.isChild}
        >
          Add field condition
        </Button>
        {props.level === 1 && (
          <Button
            type='text'
            className={`${styles.addConditionButton} ${props.isChild ? styles.groupConditionButton : ''}`}
            onClick={addGroup}
            block={props.isChild}
          >
            Add condition group
          </Button>
        )}
      </div>
    </div>
  );
};
