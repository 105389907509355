import React, { useState } from 'react';
import { Button, Typography, Checkbox } from 'antd';
import { ReactComponent as MinusIcon } from 'svg/graphicEditor/minus-big-black.svg';
import { ReactComponent as PlusIcon } from 'svg/graphicEditor/plus-big-black.svg';
import { cropImage } from '../../../services/ImageCropService';
import globalStyles from '../../GlobalGraphicEditor.module.css';

const { Text } = Typography;

export default function CroppingControl({
  image,
  background,
  scaleImage,
  onPropertyChanged,
}) {
  const [isControlOpen, setControlOpen] = useState(false);
  const [cropBackground, setCropBackground] = useState(
    image.getAttr('cropBackground') || false,
  );
  const [useFirstPixel, setUseFirstPixel] = useState(
    image.getAttr('useFirstPixel') || false,
  );

  const handleCropBackgroundChange = async (checked) => {
    setCropBackground(checked);
    image.setAttr('cropBackground', checked);
    if (checked) {
      const imageElement = image.image();
      const dataUrl = imageElement.src;
      const croppedDataUrl = await cropImage(dataUrl, useFirstPixel);
      const newImage = new window.Image();
      newImage.onload = () => {
        image.image(newImage);
        background.fillPatternImage(newImage);
        image.setAttr('mode', image.getAttr('mode'));
        image.setAttr('initWidth', newImage.width);
        image.setAttr('initHeight', newImage.height);
        scaleImage(image);
        onPropertyChanged();
      };
      newImage.src = croppedDataUrl;
    } else {
      const originalUrl = image.getAttr('url');
      const newImage = new window.Image();
      newImage.onload = () => {
        image.image(newImage);
        background.fillPatternImage(newImage);
        image.setAttr('mode', image.getAttr('mode'));
        image.setAttr('initWidth', newImage.width);
        image.setAttr('initHeight', newImage.height);
        scaleImage(image);
        onPropertyChanged();
      };
      newImage.src = originalUrl;
    }
  };

  const handleFirstPixelChange = async (checked) => {
    if (!cropBackground) return;

    setUseFirstPixel(checked);
    image.setAttr('useFirstPixel', checked);
    const originalUrl = image.getAttr('url');
    console.log(originalUrl);
    const croppedDataUrl = await cropImage(originalUrl, checked);
    const newImage = new window.Image();
    newImage.onload = () => {
      image.image(newImage);
      background.fillPatternImage(newImage);
      image.setAttr('mode', image.getAttr('mode'));
      image.setAttr('initWidth', newImage.width);
      image.setAttr('initHeight', newImage.height);
      scaleImage(image);
      onPropertyChanged();
    };
    newImage.src = croppedDataUrl;
  };

  return (
    <div>
      <div className={globalStyles.spaceBetweenBlock}>
        <Text className={globalStyles.smallTitle}>Autocropping</Text>
        <Button
          shape='circle'
          type='text'
          icon={isControlOpen ? <MinusIcon /> : <PlusIcon />}
          onClick={() => setControlOpen(!isControlOpen)}
        />
      </div>
      {isControlOpen && (
        <div
          className={`${globalStyles.innerBlock} ${globalStyles.selectBlock}`}
        >
          <Checkbox
            checked={cropBackground}
            onChange={(e) => handleCropBackgroundChange(e.target.checked)}
            style={{ fontSize: '12px' }}
          >
            Remove excessive <b>whitish</b> background around object
          </Checkbox>
          {cropBackground && (
            <Checkbox
              checked={useFirstPixel}
              onChange={(e) => handleFirstPixelChange(e.target.checked)}
              style={{ marginLeft: '20px', fontSize: '10px' }}
            >
              Use first pixel color (for non-whitish background)
            </Checkbox>
          )}
        </div>
      )}
    </div>
  );
}
