import React, { useEffect, useState } from 'react';

import SelectControl from '../../controls/SelectControl/SelectControl';
import PositionSizeControl from '../../controls/PositionSizeControl/PositionSizeControl';
import PositioningControl from '../../controls/PositioningControl/PositioningControl';
import DataProperties from '../ImageData/ImageData';
import { refreshTransform } from '../PropertiesHelper';
import RotationControl from '../../controls/RotationControl/RotationControl';
import BackgroundBorder from '../../controls/BackgroundBorderControl/BackgroundBorderControl';
import OpacityControl from '../../controls/OpacityControl/OpacityControl';
import CroppingControl from '../../controls/CroppingControl/CroppingControl';
import {
  transformerAnchors,
  transformerAnchorsFull,
} from '../../../../../constants';

import globalStyles from '../../GlobalGraphicEditor.module.css';

export default function (props) {
  const image = props.group.findOne('Image');
  const background = props.group.findOne('.background');

  const [layoutPositioning, setLayoutPositioning] = useState();
  const [mode, setMode] = useState();
  const [refreshSizeToggle, setRefreshSizeToggle] = useState();

  const modeDataSource = [
    { id: 'fit', name: 'fit' },
    { id: 'scale', name: 'scale' },
    { id: 'stretch', name: 'stretch' },
    { id: 'flex', name: 'flex' },
  ];
  useEffect(() => {
    const updateProperty = () => {
      const transformer = props.stage.findOne('Transformer');
      if (transformer) {
        if (mode === 'flex') {
          transformer.enabledAnchors(transformerAnchors);
        } else {
          transformer.enabledAnchors(transformerAnchorsFull);
        }
      }
      propertyChanged();
    };
    if (image && mode) {
      updateProperty();
    }
  }, [image, mode]);

  useEffect(() => {
    updateRefreshSizeToggle();
  }, [props.refreshToggle]);

  useEffect(() => {
    setMode(image.getAttr('mode'));
  }, [props.group, image, background, props.refreshToggle]);

  const changeMode = (val) => {
    image.setAttr('mode', val);
    props.scaleImage(image);
    setMode(val);
    propertyChanged();
  };

  const propertyChanged = () => {
    props.scaleImage(image);
    refreshTransform(props.group);

    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const imageChanged = () => {
    if (props.onPropertyChanged) {
      props.onPropertyChanged();
    }
  };

  const updateRefreshSizeToggle = () => {
    setRefreshSizeToggle((refreshSizeToggle) => !refreshSizeToggle);
  };

  const rotationChanged = () => {
    updateRefreshSizeToggle();
    propertyChanged();
  };

  return (
    <div>
      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.bottomBigPaddings}`}
      >
        <DataProperties
          product={props.product}
          group={props.group}
          scaleImage={props.scaleImage}
          uploadFile={props.uploadFile}
          userImages={props.userImages}
          deleteImage={props.deleteImage}
          onPropertyChanged={propertyChanged}
          onImageChanged={imageChanged}
        />
      </div>

      <div
        className={`${globalStyles.bottomBorderDivider} ${globalStyles.selectBlock} ${globalStyles.gapBlock}`}
      >
        <PositionSizeControl
          element={background}
          group={props.group}
          layoutPositioning={layoutPositioning}
          refreshToggle={refreshSizeToggle}
          onPropertyChanged={propertyChanged}
        />
        <PositioningControl
          group={props.group}
          attachToElement={props.attachToElement}
          detachElement={props.detachElement}
          refreshToggle={props.refreshToggle}
          setLayoutPositioning={setLayoutPositioning}
        />
        <SelectControl
          label='Mode'
          dataSource={modeDataSource}
          value={mode}
          onChange={changeMode}
        />
      </div>

      <div className={globalStyles.bottomBorderDivider}>
        <CroppingControl
          image={image}
          background={background}
          scaleImage={props.scaleImage}
          onPropertyChanged={propertyChanged}
        />
      </div>

      <div className={globalStyles.bottomBorderDivider}>
        <BackgroundBorder
          element={background}
          onPropertyChanged={propertyChanged}
          image={image}
        />
      </div>

      <div className={globalStyles.bottomBorderDivider}>
        <RotationControl
          group={props.group}
          element={background}
          refreshToggle={props.refreshToggle}
          onPropertyChanged={rotationChanged}
        />
      </div>

      <div className={globalStyles.bottomBorderDivider}>
        <OpacityControl
          element={props.group}
          onPropertyChanged={propertyChanged}
        />
      </div>
    </div>
  );
}
